<template>
    <div>
        <div class="searh_container">
            <div class="search_tab">
                <div class="flex">
                    <img src="@/assets/images/search/menu.svg" alt="" @click="setListVisible" class="menu" />
                    <div class="items" :class="{ active: tabVisible }" @click="setTabVisible(true)">Items</div>
                    <div class="activity" :class="{ active: !tabVisible }" @click="setTabVisible(false)" v-show="!collection">Activity</div>
                </div>
                <div class="screen">
                    <a-dropdown :trigger="['hover']" overlayClassName="screen_drop">
                        <a class="ant-dropdown-link">
                            {{ sortTitle }}
                            <img src="@/assets/images/market-detail/down_mo.png" alt="" slot="icon" class="down" />
                        </a>
                        <a-menu slot="overlay">
                            <a-menu-item v-for="(item, i) in sortList" :key="i" @click="clickSort(item)">
                                <a>{{ item.name }}</a>
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </div>
            </div>
            <div class="search_main" v-show="tabVisible">
                <div class="menu_list" v-if="listVisible && !isMobile">
                    <a-menu style="width: 282px" :open-keys.sync="openKeys" mode="inline">
                        <a-sub-menu key="sub1">
                            <span slot="title">
                                <span>Trading Methods</span>
                                <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                            </span>

                            <a-menu-item key="1">
                                <a-checkbox-group v-model="TradcheCkedList">
                                    <div class="flex" v-for="(item, i) in tradingList" :key="i" @click="clickChecked1(item.value)">
                                        {{ item.name }}
                                        <a-checkbox :value="item.value" @click.stop @click="getSearchListAgain"> </a-checkbox>
                                    </div>
                                </a-checkbox-group>
                            </a-menu-item>
                        </a-sub-menu>
                        <a-sub-menu key="sub2">
                            <span slot="title">
                                <span>Categories</span>
                                <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                            </span>
                            <a-menu-item key="1">
                                <a-checkbox-group v-model="categoryCheckedList">
                                    <div class="flex" v-for="(item, i) in categoryList" :key="i" @click="clickChecked2(item.name)">
                                        {{ item.name }}
                                        <a-checkbox :value="item.name" @click.stop @click="getSearchListAgain"> </a-checkbox>
                                    </div>
                                </a-checkbox-group>
                            </a-menu-item>
                        </a-sub-menu>
                        <a-sub-menu key="sub3">
                            <span slot="title">
                                <span>Price</span>
                                <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                            </span>
                            <a-menu-item key="1">
                                <div class="price">
                                    <ul>
                                        <li>
                                            <a-select dropdownClassName="price_size" v-model="valuePrice">
                                                <template slot="suffixIcon">
                                                    <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                                                </template>
                                                <a-select-option v-for="(item, i) in currencyList" :key="i" :value="item.name">{{ item.name }}</a-select-option>
                                            </a-select>
                                        </li>
                                        <li>
                                            <input type="text" placeholder="Min" v-model="minValue" />
                                        </li>
                                        <p>To</p>
                                        <li>
                                            <input type="text" placeholder="Max" v-model="maxValue" />
                                        </li>
                                    </ul>
                                    <div class="apply_btn" @click="getSearchList(), clickApply()">Apply</div>
                                </div>
                            </a-menu-item>
                        </a-sub-menu>
                        <a-sub-menu key="sub4">
                            <span slot="title">
                                <span>Standards</span>
                                <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                            </span>
                            <a-menu-item key="1">
                                <a-checkbox-group v-model="standardCheckedList">
                                    <div class="flex" v-for="(item, i) in standardList" :key="i" @click="clickChecked3(item)">
                                        {{ item }}
                                        <a-checkbox :value="item" @click.stop @click="getSearchListAgain"> </a-checkbox>
                                    </div>
                                </a-checkbox-group>
                            </a-menu-item>
                        </a-sub-menu>
                        <a-sub-menu key="sub5">
                            <span slot="title">
                                <span>Collections</span>
                                <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                            </span>
                            <a-menu-item key="1">
                                <a-checkbox-group v-model="collectCheckedList">
                                    <div class="flex" v-for="(item, i) in collectList" :key="i" @click="clickChecked4(item.identify)">
                                        {{ item.identify }}
                                        <a-checkbox :value="item.identify" @click.stop @click="getSearchListAgain"> </a-checkbox>
                                    </div>
                                </a-checkbox-group>
                            </a-menu-item>
                        </a-sub-menu>
                        <a-sub-menu key="sub6">
                            <span slot="title">
                                <span>Chain</span>
                                <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                            </span>
                            <a-menu-item key="1">
                                <a-checkbox-group v-model="chainCheckedList">
                                    <div class="flex" v-for="(item, i) in chainList" :key="i" @click="clickChecked5(item.name)">
                                        {{ item.name }}
                                        <a-checkbox :value="item.name" @click.stop @click="getSearchListAgain"> </a-checkbox>
                                    </div>
                                </a-checkbox-group>
                            </a-menu-item>
                        </a-sub-menu>
                        <a-sub-menu key="sub7">
                            <span slot="title">
                                <span>Currency</span>
                                <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                            </span>
                            <a-menu-item key="1">
                                <a-checkbox-group v-model="currencyCheckedList">
                                    <div class="flex" v-for="(item, i) in currencyList" :key="i" @click="clickChecked6(item.name)">
                                        {{ item.name }}
                                        <a-checkbox :value="item.name" @click.stop @click="getSearchListAgain"> </a-checkbox>
                                    </div>
                                </a-checkbox-group>
                            </a-menu-item>
                        </a-sub-menu>
                    </a-menu>
                </div>
                <div class="nft_list">
                    <div class="collection" v-if="collection && collectionList.length > 0">
                        <h4>Collection</h4>
                        <div class="collection_main">
                            <swiper :options="swiperOption" ref="mySwiper">
                                <swiper-slide v-for="(item, i) in collectionList" :key="i">
                                    <div @click="collectionClick(item.identify)">
                                        <div class="slide_bg">
                                            <img :src="item.background" alt="" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0" />
                                            <img :src="item.icon" alt="" class="small_bg" />
                                        </div>
                                        <p>{{ item.name }}</p>
                                    </div>
                                </swiper-slide>
                                <div class="swiper-button-prev" slot="button-prev"></div>
                                <div class="swiper-button-next" slot="button-next"></div>
                            </swiper>
                        </div>
                    </div>
                    <div class="nft_menu" v-if="!isMobile">
                        <ul>
                            <li v-for="(item, i) in TradcheCkedList" :key="i">
                                <span v-for="(data, j) in tradingList" :key="j">
                                    <template v-if="item == data.value">
                                        {{ data.name }}
                                        <img src="@/assets/images/market-detail/close_ico.svg" alt="" @click="clickChecked1(data.value)" />
                                    </template>
                                </span>
                            </li>
                            <li v-for="(item, i) in categoryCheckedList" :key="i + 10">
                                {{ item }}
                                <img src="@/assets/images/market-detail/close_ico.svg" alt="" @click="clickChecked2(item)" />
                            </li>
                            <li v-for="(item, i) in standardCheckedList" :key="i + 20">
                                {{ item }}
                                <img src="@/assets/images/market-detail/close_ico.svg" alt="" @click="clickChecked3(item)" />
                            </li>
                            <li v-for="(item, i) in collectCheckedList" :key="i + 30">
                                {{ item }}
                                <img src="@/assets/images/market-detail/close_ico.svg" alt="" @click="clickChecked4(item)" />
                            </li>
                            <li v-for="(item, i) in chainCheckedList" :key="i + 40">
                                {{ item }}
                                <img src="@/assets/images/market-detail/close_ico.svg" alt="" @click="clickChecked5(item)" />
                            </li>
                            <li v-for="(item, i) in currencyCheckedList" :key="i + 50">
                                {{ item }}
                                <img src="@/assets/images/market-detail/close_ico.svg" alt="" @click="clickChecked6(item)" />
                            </li>
                            <li v-if="minValue1 || maxValue1">
                                <p v-if="minValue1">Min {{ minValue1 }} {{ valuePrice1 }}</p>
                                <p v-if="maxValue1">,max {{ maxValue1 }} {{ valuePrice1 }}</p>
                                <img src="@/assets/images/market-detail/close_ico.svg" alt="" @click="downApply" />
                            </li>
                            <li v-if="this.$route.query.keyword">
                                {{ this.$route.query.keyword }}
                                <img
                                    src="@/assets/images/market-detail/close_ico.svg"
                                    alt=""
                                    @click="
                                        () => {
                                            this.$router.push('/search');
                                        }
                                    "
                                />
                            </li>
                            <i v-if="allCheckedList[0] || minValue1 || maxValue1 || this.$route.query.keyword" @click="cleraAll">Clear All</i>
                        </ul>
                    </div>
                    <div class="nft_main">
                        <div class="refresh_box">
                            <img src="@/assets/images/search/refresh.svg" alt="" @click="getSearchList()" />
                            {{ total }} Results
                        </div>
                        <div class="search-table-scroll" v-infinite-scroll="handleInfiniteOnLoad" :infinite-scroll-disabled="busy" :infinite-scroll-distance="10">
                            <a-list :data-source="nftList">
                                <a-list-item slot="renderItem" slot-scope="item">
                                    <template v-if="item.loading">
                                        <div class="search-info skeleton-info" :class="{ accept_li: accountPage }">
                                            <div class="img"></div>
                                            <h4></h4>
                                            <div class="series" v-if="collection || accountPage"></div>
                                            <div class="flex">
                                                <div class="price_box">
                                                    <h5></h5>
                                                    <div class="price"></div>
                                                </div>
                                                <div class="time_box" :style="{ opacity: 1 }"></div>
                                            </div>
                                            <div class="end_time"></div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="search-info" @click="nftClick(item)">
                                            <img :src="ipfs(item.token_icon)" class="img" />
                                            <h4>{{ item.token_name }}</h4>
                                            <div class="series" v-if="collection || accountPage">
                                                <img :src="item.collect_icon" class="series_bg" />
                                                <h5>{{ item.collect_name }}</h5>
                                            </div>
                                            <div class="flex">
                                                <div class="price_box" :style="{ opacity: item.price ? 1 : 0 }">
                                                    <h5>Price</h5>
                                                    <div class="price">
                                                        <img :src="item.pay_token_icon" alt="" />
                                                        <p>{{ item.price | decimals }}</p>
                                                    </div>
                                                </div>
                                                <div class="time_box" :style="{ opacity: [1, 2, 4].includes(item.type) ? 1 : 0 }">
                                                    <template v-if="item.type == 2">
                                                        <div class="times">
                                                            <p v-if="isMobile">Trade<br />Share</p>
                                                            <p v-else>Trade Share</p>
                                                            <span>{{ item.transfer_amount }}</span>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="times" v-if="item.support_eip == 'ERC-5187'">
                                                            <p v-if="isMobile">Rental<br />Share</p>
                                                            <p v-else>Rental Share</p>
                                                            <span>{{ item.rental_amount }}</span>
                                                        </div>
                                                        <div class="day">
                                                            <p v-if="isMobile">Rental<br />Period</p>
                                                            <p v-else>Rental Period</p>
                                                            <span>{{ timeToString(item.rental_period) }}</span>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="end_time" v-if="endTime(item.end_time) != 0">Ends in {{ endTime(item.end_time) }} hours</div>
                                        </div>
                                    </template>
                                </a-list-item>
                            </a-list>
                        </div>
                    </div>
                </div>
            </div>
            <div class="activity_box" v-show="!tabVisible">
                <div class="lables_box" v-if="listVisible && !isMobile">
                    <a-radio-group button-style="solid" v-model="activity.type" @change="getActivityAgain">
                        <a-radio-button v-for="(val, key, index) in activity.typeDict" :key="index" :value="key">
                            {{ val }}
                        </a-radio-button>
                    </a-radio-group>
                </div>
                <div class="activity_main">
                    <div class="refresh" @click="getActivityAgain">
                        <img src="@/assets/images/search/refresh.svg" alt="" />
                        Auto Refresh
                    </div>
                    <div class="activity-table-scroll" v-infinite-scroll="handleInfiniteOnLoad1" :infinite-scroll-disabled="activity.busy" :infinite-scroll-distance="10">
                        <a-list :data-source="activity.list">
                            <a-list-item slot="renderItem" slot-scope="item">
                                <div class="activity-info">
                                    <template v-if="!isMobile">
                                        <div class="user_img" @click="clickActivity(item)"><img :src="item.icon" /></div>
                                        <div class="user_information">
                                            <div>
                                                <div class="user_name">
                                                    <p @click="clickActivity(item)">{{ item.name }}</p>
                                                    <span>{{ activity.typeDict[item.type] }}</span>
                                                </div>
                                                <div class="right_box">
                                                    <div class="user_from">
                                                        From
                                                        <p>{{ item.from | showAddress }}</p>
                                                        to
                                                        <p>{{ item.to | showAddress }}</p>
                                                        <span>
                                                            <a-popover>
                                                                <template slot="content">
                                                                    <p>{{ timesTime(item.datetime) }}</p>
                                                                </template>
                                                                <a-button type="primary"> {{ transformTime(item.datetime) }} ago </a-button>
                                                            </a-popover>
                                                        </span>
                                                        <a :href="item.link" target="_blank"></a>
                                                        <template v-if="item.end_time">
                                                            <p v-if="item.end_time > now">Expiry {{ leftTime(item.end_time) }}</p>
                                                            <p v-else>Canceled</p>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="price_box" v-if="item.type != 2">
                                                    <img src="@/assets/images/market-detail/office_ico.svg" alt="" />
                                                    <p>{{ item.price | decimals }}</p>
                                                    <span>(${{ item.value | decimals(2) }})</span>
                                                </div>
                                                <div class="rent-box" v-if="[1, 4].includes(item.order_type)">
                                                    <p v-if="item.support_eip == 'ERC-5187'">Rental Share {{ item.rental_amount }}</p>
                                                    <p>Rental Period {{ timeToString(item.rental_period) }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="flex-box align-start">
                                            <div class="user_img" @click="clickActivity(item)"><img :src="item.icon" /></div>
                                            <div class="user_top">
                                                <div class="nft_name">
                                                    <p @click="clickActivity(item)">{{ item.name }}</p>
                                                    <span>{{ activity.typeDict[item.type] }}</span>
                                                </div>
                                                <div class="nft_price" v-if="item.type != 2">
                                                    <img :src="item.pay_token_icon" alt="" />
                                                    <p>{{ item.price | decimals }}</p>
                                                    <span>(${{ item.value | decimals(2) }})</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="user_from">
                                            From
                                            <p>{{ item.from | showAddress }}</p>
                                            to
                                            <p>{{ item.to | showAddress }}</p>
                                            <span>
                                                <a-popover placement="bottom">
                                                    <template slot="content">
                                                        <p>{{ timesTime(item.datetime) }}</p>
                                                    </template>
                                                    <a-button type="primary"> {{ transformTime(item.datetime) }} ago </a-button>
                                                </a-popover>
                                            </span>
                                            <a :href="item.link" target="_blank"></a>
                                        </div>
                                        <div class="rent-box">
                                            <template v-if="item.end_time">
                                                <p v-if="item.end_time > now">Expiry {{ leftTime(item.end_time) }}</p>
                                                <p v-else>Canceled</p>
                                            </template>
                                            <template v-if="[1, 4].includes(item.order_type)">
                                                <p v-if="item.support_eip == 'ERC-5187'">Rental Share {{ item.rental_amount }}</p>
                                                <p>Rental Period {{ timeToString(item.rental_period) }}</p>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </a-list-item>
                        </a-list>
                    </div>
                </div>
            </div>
            <!-- menu list drawer -->
            <a-drawer placement="right" :closable="false" :visible="listVisible" wrapClassName="menu_drawer" v-if="isMobile && tabVisible">
                <img src="@/assets/images/search/close_mo.svg" alt="" class="close" @click="setListVisible" />
                <a-menu style="width: 100%" :open-keys.sync="openKeys" mode="inline">
                    <a-sub-menu key="sub1">
                        <span slot="title">
                            <span>Trading Methods</span>
                            <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                        </span>

                        <a-menu-item key="1">
                            <a-checkbox-group v-model="TradcheCkedList">
                                <div class="flex" v-for="(item, i) in tradingList" :key="i" @click="clickChecked1(item.value)">
                                    {{ item.name }}
                                    <a-checkbox :value="item.value" @click.stop @click="getSearchListAgain"> </a-checkbox>
                                </div>
                            </a-checkbox-group>
                        </a-menu-item>
                    </a-sub-menu>
                    <a-sub-menu key="sub2">
                        <span slot="title">
                            <span>Categories</span>
                            <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                        </span>
                        <a-menu-item key="1">
                            <a-checkbox-group v-model="categoryCheckedList">
                                <div class="flex" v-for="(item, i) in categoryList" :key="i" @click="clickChecked2(item.name)">
                                    {{ item.name }}
                                    <a-checkbox :value="item.name" @click.stop @click="getSearchListAgain"> </a-checkbox>
                                </div>
                            </a-checkbox-group>
                        </a-menu-item>
                    </a-sub-menu>
                    <a-sub-menu key="sub3">
                        <span slot="title">
                            <span>Price</span>
                            <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                        </span>
                        <a-menu-item key="1">
                            <div class="price">
                                <ul>
                                    <li>
                                        <a-select dropdownClassName="price_size" v-model="valuePrice">
                                            <template slot="suffixIcon">
                                                <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                                            </template>
                                            <a-select-option v-for="(item, i) in currencyList" :key="i" :value="item.name">{{ item.name }}</a-select-option>
                                        </a-select>
                                    </li>
                                    <li>
                                        <input type="text" placeholder="Min" v-model="minValue" />
                                    </li>
                                    <p>To</p>
                                    <li>
                                        <input type="text" placeholder="Max" v-model="maxValue" />
                                    </li>
                                </ul>
                                <div class="apply_btn" @click="getSearchList(), clickApply()">Apply</div>
                            </div>
                        </a-menu-item>
                    </a-sub-menu>
                    <a-sub-menu key="sub4">
                        <span slot="title">
                            <span>Standards</span>
                            <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                        </span>
                        <a-menu-item key="1">
                            <a-checkbox-group v-model="standardCheckedList">
                                <div class="flex" v-for="(item, i) in standardList" :key="i" @click="clickChecked3(item)">
                                    {{ item }}
                                    <a-checkbox :value="item" @click.stop @click="getSearchListAgain"> </a-checkbox>
                                </div>
                            </a-checkbox-group>
                        </a-menu-item>
                    </a-sub-menu>
                    <a-sub-menu key="sub5">
                        <span slot="title">
                            <span>Collections</span>
                            <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                        </span>
                        <a-menu-item key="1">
                            <a-checkbox-group v-model="collectCheckedList">
                                <div class="flex" v-for="(item, i) in collectList" :key="i" @click="clickChecked4(item.identify)">
                                    {{ item.identify }}
                                    <a-checkbox :value="item.identify" @click.stop @click="getSearchListAgain"> </a-checkbox>
                                </div>
                            </a-checkbox-group>
                        </a-menu-item>
                    </a-sub-menu>
                    <a-sub-menu key="sub6">
                        <span slot="title">
                            <span>Chain</span>
                            <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                        </span>
                        <a-menu-item key="1">
                            <a-checkbox-group v-model="chainCheckedList">
                                <div class="flex" v-for="(item, i) in chainList" :key="i" @click="clickChecked5(item.name)">
                                    {{ item.name }}
                                    <a-checkbox :value="item.name" @click.stop @click="getSearchListAgain"> </a-checkbox>
                                </div>
                            </a-checkbox-group>
                        </a-menu-item>
                    </a-sub-menu>
                    <a-sub-menu key="sub7">
                        <span slot="title">
                            <span>Currency</span>
                            <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                        </span>
                        <a-menu-item key="1">
                            <a-checkbox-group v-model="currencyCheckedList">
                                <div class="flex" v-for="(item, i) in currencyList" :key="i" @click="clickChecked6(item.name)">
                                    {{ item.name }}
                                    <a-checkbox :value="item.name" @click.stop @click="getSearchListAgain"> </a-checkbox>
                                </div>
                            </a-checkbox-group>
                        </a-menu-item>
                    </a-sub-menu>
                </a-menu>
            </a-drawer>
            <!-- end -->
            <!-- lable drawer -->
            <a-drawer placement="right" :closable="false" :visible="listVisible" wrapClassName="menu_drawer" v-if="isMobile && !tabVisible">
                <img src="@/assets/images/search/close_mo.svg" alt="" class="close" @click="setListVisible" />
                <a-radio-group button-style="solid" v-model="activity.type" @change="getActivityAgain">
                    <a-radio-button v-for="(val, key, index) in activity.typeDict" :key="index" :value="key">
                        {{ val }}
                    </a-radio-button>
                </a-radio-group>
            </a-drawer>
            <!-- end -->
        </div>
        <a-modal v-model="owner.visible" title="Owned by" @ok="() => (owner.visible = false)" :centered="true" :footer="null" class="owner-modal">
            <p v-for="address in owner.list" :key="address" @click="clickActivity(owner.item, address)">{{ address }}</p>
        </a-modal>
    </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import { mapState } from 'vuex';
import { activity, collectionDetail, search, userAccount } from '@/api/index.js';
import infiniteScroll from 'vue-infinite-scroll';
import moment from 'moment';
const CID = require('cids');
export default {
    name: 'search',
    directives: { infiniteScroll },
    components: {
        swiper,
        swiperSlide,
    },
    props: {
        collection: {
            type: Boolean,
            default: false,
        },
        accountPage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            listVisible: false, //show menu
            tabVisible: true, //tab
            // show menu-item
            openKeys: ['sub1'],
            // end
            collectionList: [],
            nftList: [],
            nftListOld: [],
            TradcheCkedList: [],
            categoryCheckedList: [],
            standardCheckedList: [],
            collectCheckedList: [],
            chainCheckedList: [],
            currencyCheckedList: [],
            allCheckedList: [],
            sortTitle: 'Sort By',
            sortType: '',
            sortList: [
                { name: 'Recently Listed', type: 'newest' },
                { name: 'Recently Sold', type: 'sold' },
                { name: 'Ending Soon', type: 'endTime' },
                { name: 'Price Low To High', type: 'priceAsc' },
                { name: 'Price High To Low', type: 'priceDesc' },
            ],
            valuePrice: 'ETH',
            valuePrice1: '',
            minValue: '',
            maxValue: '',
            minValue1: '',
            maxValue1: '',
            tradingList: [],
            categoryList: [],
            standardList: [],
            collectList: [],
            chainList: [],
            currencyList: [],
            swiperOption: {
                notNextTick: true,
                initialSlide: 0,
                observer: true,
                observeParents: true,
                slidesPerView: 'auto',
                // spaceBetween: 24,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                speed: 500,
                lazy: true,
            },
            loading: false,
            busy: false,
            skip: 0,
            total: 0,
            activity: {
                list: [],
                typeDict: {},
                type: '',
                pagination: {
                    current: 1,
                    total: 0,
                    pageSize: 20,
                    size: 'small',
                },
                loading: false,
                busy: false,
            },
            now: parseInt(new Date().getTime() / 1000),
            owner: {
                list: [],
                visible: false,
                item: {},
            },
        };
    },
    mounted() {
        if (!this.isMobile) {
            this.listVisible = true;
        }
        this.getQuery();
        this.getSearchList();
        this.allCheckedListMerge();
        this.getActivity();
    },
    methods: {
        getQuery() {
            this.TradcheCkedList = typeof this.$route.query.tradingMethods == 'string' ? [`${this.$route.query.tradingMethods}`] : this.$route.query.tradingMethods || [];
            this.categoryCheckedList = typeof this.$route.query.categories == 'string' ? [`${this.$route.query.categories}`] : this.$route.query.categories || [];
            this.standardCheckedList = typeof this.$route.query.standards == 'string' ? [`${this.$route.query.standards}`] : this.$route.query.standards || [];
            this.collectCheckedList = typeof this.$route.query.collections == 'string' ? [`${this.$route.query.collections}`] : this.$route.query.collections || [];
            this.chainCheckedList = typeof this.$route.query.chain == 'string' ? [`${this.$route.query.chain}`] : this.$route.query.chain || [];
            this.currencyCheckedList = typeof this.$route.query.currency == 'string' ? [`${this.$route.query.currency}`] : this.$route.query.currency || [];
            this.minValue1 = this.minValue = this.$route.query.priceMin || '';
            this.maxValue1 = this.maxValue = this.$route.query.priceMax || '';
            this.valuePrice1 = this.$route.query.priceCurrency || '';
        },
        timeToString(time) {
            time = parseInt(time);
            if (time === 0) {
                return '';
            }
            if (time <= 60) {
                return time + ' seconds';
            }
            if (time <= 3600) {
                return parseInt(time / 60) + ' Minus';
            }
            if (time <= 86400) {
                return parseInt(time / 3600) + ' Hours';
            }
            if (time <= 2 * 86400) {
                return parseInt(time / 86400) + ' Day';
            }
            return parseInt(time / 86400) + ' Days';
        },
        leftTime(time) {
            time = parseInt(time);
            let now = parseInt(Date.now() / 1000);
            if (time < now) {
                return '';
            }
            return this.timeToString(time-now);
        },
        ipfs(uri) {
            if (uri.substring(0, 4) != 'ipfs') {
                return uri;
            }
            let v1;
            let paths = uri.substring(7).split('/');
            if (paths[0].length == 46) {
                v1 = new CID(paths[0]).toV1().toString('base32');
            } else {
                v1 = uri;
            }
            let path = '';
            if (paths.length > 1) {
                path = '/' + paths.splice(1).join('/');
            }
            return `https://${v1}.ipfs.dweb.link${path}`;
        },
        nftClick(item) {
            let url = `/market/${item.chain_name}/${item.collect_contract}/${item.token_id}`;
            if (item.support_eip == 'ERC-1155' || item.support_eip == 'ERC-5187') {
                url = url + `/${item.owner}`;
            }
            this.$router.push(url);
        },
        handleInfiniteOnLoad() {
            if (this.loading) return;
            if (this.nftList.length >= this.total) {
                this.busy = true;
                this.loading = false;
                return;
            }
            this.loading = true;
            let nullArr = [];
            for (let i = 0; i < 20; i++) {
                nullArr.push({ loading: true });
            }
            this.nftListOld = this.nftList;
            this.nftList = this.nftList.concat(nullArr);
            this.getSearchList(this.nftListOld.length);
        },
        getSearchList(skip) {
            if (!skip) {
                skip = 0;
            }
            let dataList = {
                sort_type: this.sortType,
                type: this.TradcheCkedList,
                category: this.categoryCheckedList,
                standard_eip: this.standardCheckedList,
                identify: this.collectCheckedList,
                chain: this.chainCheckedList,
                currency: this.currencyCheckedList,
                price_min: this.minValue,
                price_max: this.maxValue,
                skip,
            };
            if (this.$route.query.keyword) {
                dataList.keyword = this.$route.query.keyword;
            }
            if (this.$route.params.identify) {
                dataList.identify = this.$route.params.identify;
                collectionDetail(dataList)
                    .then((data) => {
                        this.resultData(data, skip);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else if (this.accountPage) {
                dataList.account = this.$route.params.account || this.account;
                userAccount(dataList)
                    .then((data) => {
                        this.resultData(data, skip);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                search(dataList)
                    .then((data) => {
                        this.resultData(data, skip);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        resultData(data, skip) {
            this.tradingList = data.extra.type_list;
            this.categoryList = data.extra.category_list;
            this.standardList = data.extra.standard_list;
            this.collectList = data.extra.collect_list;
            this.chainList = data.extra.chain_list;
            this.currencyList = data.extra.currency_list;
            this.collectionList = data.data.collection;
            let nftArr = this.accountPage ? data.data : data.data.nft;
            this.total = this.accountPage ? data.extra.total : data.extra.nft_total;
            if (skip == 0) {
                this.nftList = nftArr;
            } else {
                this.nftListOld = this.nftListOld.concat(nftArr);
                this.nftList = this.nftListOld;
            }
            this.loading = false;
        },
        getSearchListAgain() {
            setTimeout(() => {
                this.getSearchList(), this.allCheckedListMerge(), this.searchQuery();
            }, 0);
        },
        handleInfiniteOnLoad1() {
            if (this.tabVisible) return;
            if (this.activity.loading) return;
            if (this.activity.list.length >= this.activity.pagination.total) {
                this.activity.busy = true;
                this.activity.loading = false;
                return;
            }
            this.activity.loading = true;
            this.getActivity(this.activity.list.length);
        },
        getActivity(skip) {
            if (!skip) {
                skip = 0;
            }
            activity({
                identify: this.$route.params.identify || '',
                account: this.$route.params.account || '',
                type: this.activity.type,
                skip,
            }).then(({ data, extra }) => {
                if (skip == 0) {
                    this.activity.list = data;
                } else {
                    this.activity.list = this.activity.list.concat(data);
                }
                this.activity.pagination.total = extra.total;
                this.activity.typeDict = extra.type_dict;
                this.activity.loading = false;
            });
        },
        getActivityAgain() {
            this.getActivity();
        },
        //endTime
        endTime(time) {
            let now = parseInt(new Date().getTime() / 1000);
            let _differTime = time - now;
            if (_differTime <= 0 || _differTime > 86400) {
                return 0;
            } else {
                return Math.floor(_differTime / 3600);
            }
        },
        // change menu
        setListVisible() {
            this.listVisible = this.listVisible ? false : true;
        },
        //change tab
        setTabVisible(key) {
            this.tabVisible = key;
        },
        // all CheckedList
        allCheckedListMerge() {
            this.allCheckedList = this.TradcheCkedList.concat(this.categoryCheckedList)
                .concat(this.standardCheckedList)
                .concat(this.collectCheckedList)
                .concat(this.chainCheckedList)
                .concat(this.currencyCheckedList);
        },
        cleraAll() {
            (this.allCheckedList = []),
                (this.TradcheCkedList = []),
                (this.categoryCheckedList = []),
                (this.standardCheckedList = []),
                (this.collectCheckedList = []),
                (this.chainCheckedList = []),
                (this.currencyCheckedList = []);
            this.downApply();
            this.$router.push(`${this.$route.path}`);
            this.getSearchListAgain();
        },
        clickApply() {
            (this.minValue1 = this.minValue), (this.maxValue1 = this.maxValue);
            this.valuePrice1 = this.valuePrice;
            this.searchQuery();
        },
        downApply() {
            (this.minValue1 = this.minValue = ''), (this.maxValue1 = this.maxValue = '');
            this.valuePrice1 = '';
            this.getSearchListAgain();
        },
        searchQuery() {
            this.$router.push({
                path: `${this.$route.path}`,
                query: {
                    tradingMethods: this.TradcheCkedList,
                    categories: this.categoryCheckedList,
                    standards: this.standardCheckedList,
                    collections: this.collectCheckedList,
                    chain: this.chainCheckedList,
                    currency: this.currencyCheckedList,
                    priceMin: this.minValue1 == '' ? [] : this.minValue1,
                    priceMax: this.maxValue1 == '' ? [] : this.maxValue1,
                    priceCurrency: this.valuePrice1 == '' ? [] : this.valuePrice1,
                    keyword: this.$route.query.keyword,
                },
            });
        },
        clickChecked1(key) {
            let index = this.TradcheCkedList.indexOf(key);
            if (index == -1) {
                this.TradcheCkedList.push(key);
            } else {
                this.TradcheCkedList.splice(index, 1);
            }
            this.getSearchList();
            this.allCheckedListMerge();
            this.searchQuery();
        },
        clickChecked2(key) {
            let index = this.categoryCheckedList.indexOf(key);
            if (index == -1) {
                this.categoryCheckedList.push(key);
            } else {
                this.categoryCheckedList.splice(index, 1);
            }
            this.getSearchList();
            this.allCheckedListMerge();
            this.searchQuery();
        },
        clickChecked3(key) {
            let index = this.standardCheckedList.indexOf(key);
            if (index == -1) {
                this.standardCheckedList.push(key);
            } else {
                this.standardCheckedList.splice(index, 1);
            }
            this.getSearchList();
            this.allCheckedListMerge();
            this.searchQuery();
        },
        clickChecked4(key) {
            let index = this.collectCheckedList.indexOf(key);
            if (index == -1) {
                this.collectCheckedList.push(key);
            } else {
                this.collectCheckedList.splice(index, 1);
            }
            this.getSearchList();
            this.allCheckedListMerge();
            this.searchQuery();
        },
        clickChecked5(key) {
            let index = this.chainCheckedList.indexOf(key);
            if (index == -1) {
                this.chainCheckedList.push(key);
            } else {
                this.chainCheckedList.splice(index, 1);
            }
            this.getSearchList();
            this.allCheckedListMerge();
            this.searchQuery();
        },
        clickChecked6(key) {
            let index = this.currencyCheckedList.indexOf(key);
            if (index == -1) {
                this.currencyCheckedList.push(key);
            } else {
                this.currencyCheckedList.splice(index, 1);
            }
            this.getSearchList();
            this.allCheckedListMerge();
            this.searchQuery();
        },
        collectionClick(identify) {
            this.$router.push(`/collection/${identify}`);
        },
        clickSort(val) {
            this.sortTitle = val.name;
            this.sortType = val.type;
            this.getSearchList();
            this.allCheckedListMerge();
        },
        clickActivity(item, address) {
            let owner = item.owner;
            if (address) {
                owner = address;
            } else {
                if (item.support_eip == 'ERC-1155' && Array.isArray(item.owner)) {
                    this.owner.visible = true;
                    this.owner.list = item.owner;
                    this.owner.item = item;
                    return;
                }
            }
            let url = `/market/${item.chain_name}/${item.contract_address}/${item.token_id}`;
            if (item.support_eip == 'ERC-1155' || item.support_eip == 'ERC-5187') {
                url = url + `/${owner}`;
            }
            this.$router.push(url);
        },
    },
    computed: {
        ...mapState({
            isMobile: (state) => state.isMobile,
            account: (state) => state.account,
        }),
        timesTime() {
            return function (time) {
                return moment(time * 1000);
            };
        },
        transformTime() {
            return function (time) {
                let differTime = this.now - time;
                if (differTime >= 86400) {
                    return Math.floor(differTime / 86400) + ' days';
                }
                if (differTime >= 3600) {
                    return Math.floor(differTime / 3600) + ' hours';
                }
                if (differTime <= 0) {
                    differTime = 1;
                }
                return Math.floor(differTime / 60) + ' minutes';
            };
        },
    },
    watch: {
        account(val) {
            if (val) {
                this.getSearchList();
            }
        },
        isMobile(val) {
            if (val) {
                if (this.listVisible) {
                    this.listVisible = false;
                }
            }
        },
        '$route.query.keyword': {
            handler(newVal, oldVal) {
                if (newVal != oldVal) {
                    this.getSearchList();
                }
            },
            deep: true,
        },
        '$route.params.account': {
            handler(newVal, oldVal) {
                if (newVal != oldVal) {
                    this.getSearchList();
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
.screen_drop {
    .ant-dropdown-menu {
        margin-top: 18px;
        background: url('~@/assets/images/search/screen_bg.svg') no-repeat;
        padding: 0;
        box-shadow: none;
        background-size: cover;
        .ant-dropdown-menu-item {
            padding: 8px 14px;
            border-bottom: 1px solid #093e3b;
            &:last-child {
                border: none;
            }
            &:hover {
                background-color: transparent;
            }
            a {
                font-size: 14px;
                font-family: 'SFPixelate-Bold';
                color: #ffffff;
                line-height: 16px;
            }
        }
    }
}
.price_size {
    background-color: #09282c;
    .ant-select-dropdown-menu-item-selected,
    .ant-select-dropdown-menu-item {
        background-color: #09282c;
        color: #ffffff;
        font-family: 'SFPixelate-Bold';
        font-size: 12px;
        border-bottom: 1px solid #00c67f;
        &:last-child {
            border: none;
        }
    }
    .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
        background: none;
    }
    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
        background: none;
    }
}
// time ago
.ant-popover-placement-top {
    padding: 0;
    .ant-popover-arrow {
        display: none;
    }
    .ant-popover-inner {
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;
        padding: 20px 0 0 140px;
    }
    .ant-popover-inner-content {
        // margin:18px 0 0 128px;
        background-color: rgba(1, 198, 127, 0.1);
        padding: 6px 4px;
        font-size: 12px;
        font-family: 'SFPixelate-Bold';
        color: #c0d5d1;
        line-height: 14px;
    }
}
.owner-modal {
    .ant-modal-content {
        background: #0b2d33;
        box-shadow: none;
        .ant-modal-close-x {
            color: #ffffff;
        }
        .ant-modal-header {
            background: #0b2d33;
            border: none;
            .ant-modal-title {
                color: #ffffff;
            }
        }
        .ant-modal-body {
            padding-top: 0;
            p {
                color: #01aa6f;
                padding: 8px 0;
                border-bottom: 1px solid #074d40;
                cursor: pointer;
                &:last-child {
                    border: none;
                }
            }
        }
    }
}
@media screen and(max-width: 750px) {
    .screen_drop {
        width: 6.86rem;
        min-width: auto !important;
        left: 0.32rem !important;
        .ant-dropdown-menu {
            background: url('~@/assets/images/search/screenBg_mo.svg') no-repeat;
            background-size: contain;
            padding: 0 0.28rem;
            margin-top: 0.4rem;
            .ant-dropdown-menu-item {
                padding: 0.16rem 0.08rem;
                a {
                    font-size: 0.28rem;
                    line-height: 0.32rem;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
    .menu_drawer {
        .ant-drawer-content-wrapper {
            width: 100% !important;
            .ant-drawer-content {
                background-color: #0c2d33;
                .ant-drawer-body {
                    padding: 0.4rem;
                    .close {
                        float: right;
                        width: 0.36rem;
                        height: 0.36rem;
                        cursor: pointer;
                    }
                    .ant-menu-root {
                        margin-top: 0.44rem;
                        background: transparent;
                        border: none;
                        .ant-menu-submenu-open {
                            .ant-menu-submenu-title {
                                margin-bottom: 0.32rem !important;
                                span {
                                    img {
                                        transform: rotate(0deg) !important;
                                    }
                                }
                            }
                        }
                        .ant-menu-submenu-selected {
                            color: #ffffff;
                        }
                        .ant-menu-submenu-title:active,
                        .ant-menu-item:active {
                            background-color: transparent;
                        }
                        .ant-menu-submenu {
                            border-bottom: 0.02rem solid #025839;
                            .ant-menu-submenu-title {
                                padding: 0 !important;
                                height: auto;
                                margin: 0.4rem 0;
                                &:hover {
                                    color: #ffffff;
                                }
                                span {
                                    &:first-child {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        font-family: 'SFPixelate-Bold';
                                        color: #ffffff;
                                        line-height: 0.32rem;
                                        font-size: 0.28rem;
                                        img {
                                            width: 0.28rem;
                                            height: 0.28rem;
                                            transform: rotate(180deg);
                                            transition: all 0.3s;
                                        }
                                    }
                                }
                                .ant-menu-submenu-arrow {
                                    display: none;
                                }
                            }
                        }
                        .ant-menu-submenu > .ant-menu,
                        .ant-menu-item-selected {
                            background-color: transparent;
                            color: #537171;
                        }
                        .ant-menu-item {
                            width: 100%;
                            height: auto;
                            padding: 0 !important;
                            margin: 0;
                            &::after {
                                display: none;
                            }
                            &:hover {
                                color: #537171;
                            }
                            .ant-checkbox-group {
                                width: 100%;
                                padding-bottom: 0.08rem;
                                .flex {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    font-size: 0.24rem;
                                    font-family: 'SFPixelate-Bold';
                                    color: #537171;
                                    line-height: 0.28rem;
                                    margin-bottom: 0.32rem;
                                    .ant-checkbox-wrapper {
                                        .ant-checkbox-inner {
                                            border: none;
                                            background-color: #085044;
                                            width: 0.24rem;
                                            height: 0.24rem;
                                        }
                                        .ant-checkbox-checked::after {
                                            display: none;
                                        }
                                    }
                                }
                            }
                            .price {
                                ul {
                                    display: flex;
                                    align-items: center;
                                    li {
                                        background: url('~@/assets/images/search/priceBg_mo.svg') no-repeat;
                                        background-size: contain;
                                        width: 2rem;
                                        height: 0.6rem;
                                        padding: 0.16rem;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        &:first-child {
                                            padding: 0;
                                            margin-right: 0.12rem;
                                            align-items: flex-start;
                                        }
                                        input {
                                            cursor: text;
                                            width: 100%;
                                            height: 100%;
                                            border: none;
                                            background-color: transparent;
                                            font-size: 0.24rem;
                                            font-family: 'SFPixelate-Bold';
                                            color: #ffffff;
                                            &::placeholder {
                                                color: #537171;
                                            }
                                        }
                                        .ant-select {
                                            width: 100%;
                                            padding: 0.16rem;
                                            .ant-select-selection {
                                                background-color: transparent;
                                                border: none;
                                                height: auto;
                                                box-shadow: none;
                                                &:active {
                                                    box-shadow: none !important;
                                                }
                                                .ant-select-selection__rendered {
                                                    margin: 0;
                                                    font-size: 0.24rem;
                                                    font-family: 'SFPixelate-Bold';
                                                    color: #ffffff;
                                                    line-height: 0.28rem;
                                                }
                                            }
                                            .ant-select-arrow {
                                                margin-top: -0.1rem;
                                                right: 0px;
                                                img {
                                                    width: 0.2rem;
                                                    height: 0.2rem;
                                                }
                                            }
                                        }
                                    }
                                    p {
                                        font-size: 0.24rem;
                                        font-family: 'SFPixelate-Bold';
                                        color: #ffffff;
                                        line-height: 0.28rem;
                                        margin: 0 0.12rem;
                                    }
                                }
                            }
                            .apply_btn {
                                width: 100%;
                                height: 0.6rem;
                                margin: 0.24rem auto 0.4rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 0.24rem;
                                font-family: 'SFPixelate-Bold';
                                color: #ffffff;
                                line-height: 0.28rem;
                                background: url('~@/assets/images/search/applyBg_mo.svg') no-repeat;
                                background-size: contain;
                            }
                        }
                    }
                    .ant-radio-group {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;
                        width: 100%;
                        .ant-radio-button-wrapper {
                            width: 3.2rem;
                            height: 0.64rem;
                            margin: 0;
                            margin-top: 0.24rem;
                            border: 0.02rem solid #de8900;
                            text-align: center;
                            background-color: transparent;
                            border-radius: 0;
                            font-size: 0.24rem;
                            font-family: 'SFPixelate-Bold';
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #de8900;
                            line-height: 0.28rem;
                            padding: 0;
                            &:not(:first-child)::before {
                                display: none;
                            }
                            &:first-child {
                                margin-top: 0.44rem;
                            }
                            &:nth-child(2) {
                                margin-top: 0.44rem;
                            }
                        }
                        .ant-radio-button-wrapper-checked {
                            background-color: #de8900;
                            color: #052a2c;
                        }
                    }
                }
            }
        }
    }
    .price_size {
        .ant-select-dropdown-menu-item-selected,
        .ant-select-dropdown-menu-item {
            font-size: 0.24rem;
            border-bottom: 0.02rem solid #00c67f;
        }
    }
    .ant-popover-placement-bottom {
        .ant-popover-arrow {
            display: none;
        }
        .ant-popover-inner {
            background-color: transparent;
            box-shadow: none;
            border-radius: 0;
            padding-left: 0.4rem;
        }
        .ant-popover-inner-content {
            // margin:18px 0 0 128px;
            background-color: rgba(1, 198, 127, 0.1);
            padding: 0.12rem 0.08rem;
            font-size: 0.24rem;
            font-family: 'SFPixelate-Bold';
            color: #c0d5d1;
            line-height: 0.28rem;
        }
    }
}
</style>

<style lang="scss" scoped>
.flex {
    display: flex;
    align-items: center;
}
.searh_container {
    width: 1200px;
    margin: 0 auto;
    .search_tab {
        width: calc(100% - 8px);
        margin: 0 4px;
        height: 51px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: url('~@/assets/images/search/tab_bg.svg') no-repeat #0b2e33;
        position: sticky;
        position: -webkit-sticky;
        top: 72px;
        z-index: 999;
        .menu {
            margin: 0 36px 0 50px;
            width: 17px;
            height: 15px;
            cursor: pointer;
        }
        .items,
        .activity {
            font-size: 16px;
            font-family: 'SFPixelate-Bold';
            color: #ffffff;
            line-height: 17px;
            margin-left: 46px;
            padding-bottom: 2px;
            cursor: pointer;
        }
        .active {
            border-bottom: 1px solid #00c67f;
        }
        .screen {
            margin-right: 30px;
            font-size: 16px;
            font-family: 'SFPixelate-Bold';
            line-height: 17px;
            .ant-dropdown-link {
                display: flex;
                align-items: center;
                color: #ffffff;
                .down {
                    margin-left: 74px;
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }
    .search_main {
        display: flex;
        padding: 0 4px;
        width: 100%;
        .menu_list {
            width: 282px;
            margin: 24px 24px 0 0px;
            :deep(.ant-menu-root) {
                position: sticky;
                position: -webkit-sticky;
                top: 123px;
                background: #0c3336;
                border: 1px solid #01c67f;
                padding: 0 12px;
                max-height: calc(100vh - 123px);
                overflow-y: auto;
                .ant-menu-submenu-open {
                    .ant-menu-submenu-title {
                        margin-bottom: 16px !important;
                        span {
                            img {
                                transform: rotate(180deg) !important;
                            }
                        }
                    }
                }
                .ant-menu-submenu-selected {
                    color: #ffffff;
                }
                .ant-menu-submenu-title:active,
                .ant-menu-item:active {
                    background-color: transparent;
                }
                .ant-menu-submenu {
                    border-bottom: 1px solid #025839;
                    .ant-menu-submenu-title {
                        padding: 0 !important;
                        height: auto;
                        margin: 20px 0;
                        &:hover {
                            color: #ffffff;
                        }
                        span {
                            &:first-child {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                font-family: 'SFPixelate-Bold';
                                color: #ffffff;
                                line-height: 16px;
                                img {
                                    width: 14px;
                                    height: 14px;
                                    transform: rotate(0deg);
                                    transition: all 0.3s;
                                }
                            }
                        }
                        .ant-menu-submenu-arrow {
                            display: none;
                        }
                    }
                }
                .ant-menu-submenu > .ant-menu,
                .ant-menu-item-selected {
                    background-color: #0c3336;
                    color: #537171;
                }
                .ant-menu-item {
                    width: 100%;
                    height: auto;
                    padding: 0 !important;
                    margin: 0;
                    &::after {
                        display: none;
                    }
                    &:hover {
                        color: #537171;
                    }
                    .ant-checkbox-group {
                        width: 100%;
                        padding-bottom: 4px;
                        .flex {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            font-size: 12px;
                            font-family: 'SFPixelate-Bold';
                            color: #537171;
                            line-height: 14px;
                            margin-bottom: 16px;
                            .ant-checkbox-wrapper {
                                .ant-checkbox-inner {
                                    border: none;
                                    background-color: #085044;
                                    width: 14px;
                                    height: 14px;
                                }
                                .ant-checkbox-checked::after {
                                    display: none;
                                }
                            }
                        }
                    }
                    .price {
                        ul {
                            display: flex;
                            align-items: center;
                            li {
                                background: url('~@/assets/images/search/price_bg.svg') no-repeat;
                                width: 70px;
                                height: 30px;
                                padding: 8px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                &:first-child {
                                    padding: 0;
                                    margin-right: 12px;
                                    align-items: flex-start;
                                }
                                input {
                                    cursor: text;
                                    width: 100%;
                                    height: 100%;
                                    border: none;
                                    background-color: transparent;
                                    font-size: 12px;
                                    font-family: 'SFPixelate-Bold';
                                    color: #ffffff;
                                    &::placeholder {
                                        color: #537171;
                                    }
                                }
                                .ant-select {
                                    width: 100%;
                                    padding: 8px;
                                    .ant-select-selection {
                                        background-color: transparent;
                                        border: none;
                                        height: auto;
                                        box-shadow: none;
                                        &:active {
                                            box-shadow: none !important;
                                        }
                                        .ant-select-selection__rendered {
                                            margin: 0;
                                            font-size: 12px;
                                            font-family: 'SFPixelate-Bold';
                                            color: #ffffff;
                                            line-height: 14px;
                                        }
                                    }
                                    .ant-select-arrow {
                                        margin-top: -5px;
                                        right: 0px;
                                        img {
                                            width: 10px;
                                            height: 10px;
                                        }
                                    }
                                }
                            }
                            p {
                                font-size: 12px;
                                font-family: 'SFPixelate-Bold';
                                color: #ffffff;
                                line-height: 14px;
                                margin: 0 10px;
                            }
                        }
                    }
                    .apply_btn {
                        width: 258px;
                        height: 30px;
                        margin: 12px auto 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 12px;
                        font-family: 'SFPixelate-Bold';
                        color: #ffffff;
                        line-height: 14px;
                        background: url('~@/assets/images/search/apply_bg.svg') no-repeat;
                    }
                }
            }
        }
        .nft_list {
            width: 100%;
            margin-bottom: 80px;
            margin-top: 14px;
            overflow: hidden;
            .collection {
                h4 {
                    font-size: 16px;
                    font-family: 'SFPixelate-Bold';
                    color: #ffffff;
                    line-height: 17px;
                }
                .collection_main {
                    width: 100%;
                    padding-top: 24px;
                    height: 278px;
                    border-bottom: 1px solid #025839;
                    .swiper-container {
                        width: 100%;
                        height: 100%;
                        // max-width: 900px;
                    }
                    .swiper-slide {
                        width: 274px !important;
                        margin-right: 24px;
                        cursor: pointer;
                        .slide_bg {
                            background-color: #fff;
                            border: 4px solid #000000;
                            width: 100%;
                            height: 176px;
                            position: relative;
                            .small_bg {
                                width: 68px;
                                height: 68px;
                                border: 4px solid #000000;
                                position: absolute;
                                bottom: -45px;
                                left: 12px;
                            }
                        }
                        p {
                            font-size: 14px;
                            font-family: 'SFPixelate-Bold';
                            color: #ffffff;
                            line-height: 16px;
                            margin: 14px 0 0 88px;
                        }
                    }
                    .swiper-button-next {
                        width: 40px;
                        height: 40px;
                        right: 0;
                        top: auto;
                        bottom: 148px;
                        background: url('~@/assets/images/search/next.png') no-repeat;
                    }
                    .swiper-button-prev {
                        width: 40px;
                        height: 40px;
                        left: 0;
                        top: auto;
                        bottom: 148px;
                        background: url('~@/assets/images/search/next.png') no-repeat;
                        transform: rotate(180deg);
                    }
                    .swiper-button-disabled {
                        opacity: 0;
                    }
                }
            }
            .nft_main {
                width: 100%;
                margin-top: 17px;
                .refresh_box {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    font-family: 'SFPixelate-Bold';
                    color: #ffffff;
                    line-height: 14px;
                    cursor: pointer;
                    img {
                        margin-right: 8px;
                    }
                }
                .search-table-scroll {
                    :deep(.ant-list-items) {
                        margin: 0 -10px;
                        display: flex;
                        flex-wrap: wrap;
                        .ant-list-item {
                            border: none;
                            margin: 0;
                            padding: 0 10px;
                        }
                    }
                    .accept_li:hover {
                        .flex {
                            opacity: 0;
                        }
                        .accept {
                            opacity: 1;
                        }
                    }
                    .search-info {
                        width: 282px;
                        position: relative;
                        background-color: #093b3a;
                        margin-top: 24px;
                        padding: 12px;
                        padding-bottom: 46px;
                        font-family: 'SFPixelate-Bold';
                        cursor: pointer;
                        .price_box {
                            min-width: 20%;
                        }
                        .flex {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            min-height: 90px;
                            align-items: flex-start;
                        }
                        &:hover {
                            background-color: #094f44;
                        }
                        .img {
                            width: 258px;
                            height: 258px;
                        }
                        h4 {
                            font-size: 14px;
                            line-height: 16px;
                            color: #ffffff;
                            margin: 22px 0 12px 0;
                            width: 100%;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        h5 {
                            font-size: 12px;
                            color: #8babab;
                            line-height: 14px;
                        }
                        .series {
                            display: flex;
                            align-items: center;
                            margin-bottom: 14px;
                            .series_bg {
                                width: 30px;
                                height: 30px;
                                margin-right: 8px;
                            }
                            h5 {
                                font-size: 12px;
                                color: #ffffff;
                                line-height: 14px;
                            }
                        }
                        .price {
                            margin: 8px 0 12px;
                            display: flex;
                            align-items: center;
                            img {
                                width: 16px;
                                height: 16px;
                                margin-right: 4px;
                            }
                            p {
                                font-size: 16px;
                                color: #ffffff;
                                line-height: 17px;
                            }
                        }
                        .time_box {
                            display: flex;
                            height: 39px;
                            width: 100%;
                            p {
                                font-size: 12px;
                                color: #8babab;
                                line-height: 14px;
                            }
                            span {
                                font-size: 16px;
                                color: #ffffff;
                                line-height: 17px;
                                margin-top: 8px;
                                display: inline-block;
                            }
                            .times {
                                margin-right: 24px;
                            }
                        }
                        .accept {
                            position: absolute;
                            bottom: 50px;
                            left: 12px;
                            width: 258px;
                            height: 30px;
                            background: url('~@/assets/images/account/accept_bg.svg') no-repeat;
                            cursor: pointer;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 12px;
                            font-family: 'SFPixelate-Bold';
                            color: #000000;
                            line-height: 14px;
                            opacity: 0;
                        }
                        .end_time {
                            position: absolute;
                            left: 12px;
                            bottom: 16px;
                            font-size: 12px;
                            color: #de8900;
                            line-height: 14px;
                        }
                    }
                    .skeleton-info {
                        .skeleton-bg {
                            background: linear-gradient(to right, rgba(255, 255, 255, 0.5) 8%, rgba(255, 255, 255, 0.6) 36%, rgba(255, 255, 255, 0.5) 66%);
                            background-size: 300% 100%;
                            animation: ant-skeleton-loading 1.4s ease infinite;
                        }
                        .img {
                            @extend .skeleton-bg;
                        }
                        h4 {
                            min-height: 16px;
                            @extend .skeleton-bg;
                        }
                        .series {
                            @extend .skeleton-bg;
                            min-height: 30px;
                        }
                        .price_box {
                            width: 50%;
                            h5 {
                                @extend .skeleton-bg;
                                min-height: 14px;
                            }
                            .price {
                                @extend .skeleton-bg;
                                min-height: 16px;
                            }
                        }
                        .time_box {
                            width: 100%;
                            @extend .skeleton-bg;
                            min-height: 38px;
                        }
                        .end_time {
                            width: 60%;
                            @extend .skeleton-bg;
                            min-height: 14px;
                        }
                    }
                }
            }
            .nft_menu {
                width: 100%;
                margin-top: 16px;
                ul {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    font-family: 'SFPixelate-Bold';
                    li {
                        width: auto;
                        height: auto;
                        margin: 0;
                        background-color: #09282c;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 12px 20px;
                        border-radius: 8px;
                        font-size: 16px;
                        color: #ffffff;
                        margin-right: 10px;
                        margin-bottom: 5px;
                        &:hover {
                            background-color: #09282c;
                        }
                        img {
                            width: 10px;
                            height: 10px;
                            margin-left: 6px;
                        }
                    }
                    i {
                        font-size: 16px;
                        color: #ffffff;
                        font-family: 'SFPixelate-Bold';
                        margin-left: 10px;
                        &:hover {
                            color: gray;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .activity_box {
        width: 100%;
        margin-bottom: 80px;
        display: flex;
        .lables_box {
            position: sticky;
            position: -webkit-sticky;
            top: 123px;
            width: 282px;
            height: 155px;
            padding: 10px;
            border: 1px solid #01c67f;
            flex-shrink: 0;
            margin: 24px 24px 0 4px;
            .ant-radio-group {
                .ant-radio-button-wrapper {
                    width: 127px;
                    height: 26px;
                    margin-bottom: 9px;
                    padding: 0;
                    font-size: 12px;
                    font-family: 'SFPixelate-Bold';
                    color: #de8900;
                    line-height: 26px;
                    display: inline-block;
                    text-align: center;
                    border: 1px solid #de8900;
                    background: transparent;
                    border-radius: 0;
                    &:nth-child(2n) {
                        margin-left: 4px;
                    }
                    &:not(:first-child)::before {
                        display: none;
                    }
                }
                .ant-radio-button-wrapper-checked {
                    background-color: #de8900;
                    color: #052a2c;
                }
            }
        }
        .activity_main {
            width: 100%;
            margin-top: 15px;
            .refresh {
                margin-bottom: 13px;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-family: 'SFPixelate-Bold';
                color: #ffffff;
                line-height: 14px;
                cursor: pointer;
                img {
                    margin-right: 8px;
                    width: 14px;
                }
            }
            :deep(.ant-list-items) {
                .ant-list-item {
                    border: none;
                    margin: 0;
                    padding: 0;
                }
            }
            .activity-info {
                width: 100%;
                height: 76px;
                background-color: rgba(0, 197, 126, 0.1);
                margin-bottom: 8px;
                display: flex;
                padding: 16px 12px 16px 0;
                cursor: pointer;
                .user_img {
                    width: 42px;
                    height: 42px;
                    margin: 0 20px 0 24px;
                    background-color: #000000;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .user_information {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .user_name {
                        font-size: 12px;
                        font-family: 'SFPixelate-Bold';
                        line-height: 14px;
                        display: flex;
                        margin-bottom: 12px;
                        p {
                            color: #ffffff;
                        }
                        span {
                            padding: 0px 6px;
                            background-color: #de8900;
                            margin-left: 10px;
                        }
                    }
                    .right_box {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .user_from {
                            display: flex;
                            align-items: center;
                            font-size: 12px;
                            font-family: 'SFPixelate-Bold';
                            color: #517171;
                            line-height: 14px;
                            p {
                                color: #01aa6f;
                                margin: 0 26px;
                            }
                            span {
                                padding-bottom: 1px;
                                border-bottom: 1px solid #00ab6d;
                                :deep(.ant-btn) {
                                    background: transparent;
                                    border: none;
                                    height: auto;
                                    padding: 0;
                                    color: #00ab6d;
                                    font-size: 12px;
                                    font-family: 'SFPixelate-Bold';
                                    line-height: 14px;
                                }
                            }
                            a {
                                width: 16px;
                                height: 16px;
                                background: url('~@/assets/images/market-detail/share_ico.svg') no-repeat center;
                                margin-left: 10px;
                            }
                        }
                    }
                    .price_box {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        font-family: 'SFPixelate-Bold';
                        line-height: 14px;
                        p {
                            color: #ffffff;
                            margin: 0 10px;
                        }
                        span {
                            color: #bfd5d1;
                        }
                    }
                    .rent-box {
                        font-size: 12px;
                        font-family: 'SFPixelate-Bold';
                        p {
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}
@media screen and(max-width: 750px) {
    .searh_container {
        width: 100%;
        .search_tab {
            width: 6.86rem;
            margin: 0 auto;
            background: url('~@/assets/images/search/tabBg_mo.svg') no-repeat #0b2e33;
            background-size: contain;
            height: 2.1rem;
            flex-wrap: wrap;
            padding: 0.36rem;
            align-items: center;
            top: 1.44rem;
            z-index: 8;
            .menu {
                margin: 0;
                width: 0.34rem;
                height: 0.3rem;
            }
            .items,
            .activity {
                font-size: 0.32rem;
                font-family: 'SFPixelate-Bold';
                color: #ffffff;
                line-height: 0.34rem;
                margin-left: 0.92rem;
                padding-bottom: 0.02rem;
                cursor: pointer;
            }
            .items {
                margin-left: 2.4rem;
            }
            .screen {
                margin: 0;
                margin-top: 0.32rem;
                width: 100%;
                font-size: 0.32rem;
                line-height: 0.34rem;
                border-top: 0.02rem solid #046e4e;
                padding-top: 0.32rem;
                .ant-dropdown-link {
                    justify-content: space-between;
                    .down {
                        margin: 0;
                        width: 0.28rem;
                        height: 0.28rem;
                    }
                }
            }
        }
        .search_main {
            padding: 0 0.32rem;
            .nft_list {
                margin-top: 0;
                margin-bottom: 1.48rem;
                .collection {
                    margin-top: 0.4rem;
                    h4 {
                        font-size: 0.32rem;
                        line-height: 0.34rem;
                    }
                    .collection_main {
                        padding-top: 0.28rem;
                        height: 3.78rem;
                        border-bottom: 0.02rem solid #025839;
                        .swiper-slide {
                            width: 3.32rem !important;
                            margin-right: 0.22rem;
                            .slide_bg {
                                border: 0.08rem solid #000000;
                                height: 2.24rem;
                                .small_bg {
                                    width: 0.88rem;
                                    height: 0.88rem;
                                    border: 0.08rem solid #000000;
                                    bottom: -0.66rem;
                                    left: 0.12rem;
                                }
                            }
                            p {
                                font-size: 0.24rem;
                                line-height: 0.28rem;
                                margin: 0.08rem 0 0 1.16rem;
                            }
                        }
                        .swiper-button-next {
                            width: 0.8rem;
                            height: 0.8rem;
                            right: 0;
                            bottom: 1.94rem;
                            background-size: contain;
                        }
                        .swiper-button-prev {
                            width: 0.8rem;
                            height: 0.8rem;
                            bottom: 1.94rem;
                            background-size: contain;
                        }
                    }
                }
                .nft_main {
                    margin-top: 0.34rem;
                    .refresh_box {
                        font-size: 0.24rem;
                        line-height: 0.28rem;
                        img {
                            width: 0.28rem;
                            margin-right: 0.16rem;
                            margin-left: 0.16rem;
                        }
                    }
                    .search-table-scroll {
                        :deep(.ant-list-items) {
                            margin: 0;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            .ant-list-item {
                                border: none;
                                margin: 0;
                                padding: 0;
                            }
                        }
                        .search-info {
                            width: 3.32rem;
                            margin-top: 0.32rem;
                            padding: 0.12rem;
                            padding-bottom: 0.76rem;
                            .flex {
                                min-height: 2.08rem;
                            }
                            .img {
                                width: 3.08rem;
                                height: 3.08rem;
                            }
                            h4 {
                                font-size: 0.28rem;
                                line-height: 0.32rem;
                                margin: 0.24rem 0 0.12rem 0;
                            }
                            h5 {
                                font-size: 0.24rem;
                                line-height: 0.28rem;
                            }
                            .series {
                                margin-bottom: 0.36rem;
                                .series_bg {
                                    width: 0.6rem;
                                    height: 0.6rem;
                                    margin-right: 0.16rem;
                                }
                                h5 {
                                    font-size: 0.24rem;
                                    line-height: 0.28rem;
                                }
                            }
                            .price {
                                margin: 0.16rem 0 0.26rem;
                                img {
                                    width: 0.32rem;
                                    height: 0.32rem;
                                    margin-right: 0.08rem;
                                }
                                p {
                                    font-size: 0.32rem;
                                    line-height: 0.34rem;
                                }
                            }
                            .time_box {
                                height: 1.04rem;
                                p {
                                    font-size: 0.24rem;
                                    line-height: 0.28rem;
                                }
                                span {
                                    font-size: 0.28rem;
                                    line-height: 0.32rem;
                                    margin-top: 0.16rem;
                                }
                                .times {
                                    width: 0.88rem;
                                    margin-right: 0.34rem;
                                }
                            }
                            .accept {
                                width: 3.08rem;
                                height: 0.6rem;
                                bottom: 0.92rem;
                                left: 0.12rem;
                                background: url('~@/assets/images/account/acceptBg_mo.svg') no-repeat;
                                background-size: contain;
                                font-size: 0.24rem;
                                line-height: 0.28rem;
                            }
                            .end_time {
                                left: 0.12rem;
                                bottom: 0.24rem;
                                font-size: 0.24rem;
                                line-height: 0.28rem;
                            }
                        }
                        .skeleton-info {
                            h4 {
                                min-height: 0.32rem;
                            }
                            .series {
                                min-height: 0.6rem;
                            }
                            .price_box {
                                width: 50%;
                                h5 {
                                    min-height: 0.28rem;
                                }
                                .price {
                                    min-height: 0.32rem;
                                }
                            }
                            .time_box {
                                width: 100%;
                                min-height: 0.64rem;
                            }
                            .end_time {
                                width: 60%;
                                min-height: 0.28rem;
                            }
                        }
                    }
                }
            }
        }
        .activity_box {
            margin-bottom: 1.16rem;
            padding: 0.32rem;
            padding-top: 0;
            .activity_main {
                margin-top: 0.34rem;
                .refresh {
                    margin-bottom: 0.32rem;
                    font-size: 0.24rem;
                    line-height: 0.28rem;
                    img {
                        margin: 0 0.16rem;
                        width: 0.28rem;
                    }
                }
                .activity-info {
                    height: auto;
                    margin: 0.16rem 0 0 0;
                    padding: 0.34rem 0.4rem 0.3rem 0.24rem;
                    display: block;
                    .user_img {
                        display: inline-block;
                        width: 0.84rem;
                        height: 0.84rem;
                        margin: 0 0.4rem 0 0;
                    }
                    .user_top {
                        display: inline-block;
                        .nft_name {
                            display: flex;
                            font-size: 0.24rem;
                            font-family: 'SFPixelate-Bold';
                            color: #ffffff;
                            line-height: 0.28rem;
                            align-items: center;
                            span {
                                padding: 0 0.12rem;
                                background-color: #de8900;
                                color: #072a2c;
                                margin-left: 0.2rem;
                            }
                        }
                        .nft_price {
                            display: flex;
                            font-size: 0.24rem;
                            font-family: 'SFPixelate-Bold';
                            color: #bfd5d1;
                            line-height: 0.28rem;
                            margin: 0.3rem 0 0.24rem;
                            img {
                                width: 0.28rem;
                                height: 0.28rem;
                            }
                            p {
                                color: #ffffff;
                                margin: 0 0.24rem 0 0.12rem;
                            }
                        }
                    }
                    .user_from {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        font-size: 0.24rem;
                        line-height: 0.28rem;
                        font-family: 'SFPixelate-Bold';
                        color: #517171;
                        p {
                            color: #01aa6f;
                            margin: 0 0.4rem;
                            &:last-of-type {
                                margin-right: 0;
                            }
                        }
                        span {
                            padding-bottom: 1px;
                            border-bottom: 1px solid #00ab6d;
                            :deep(.ant-btn) {
                                background: transparent;
                                border: none;
                                height: auto;
                                padding: 0;
                                color: #00ab6d;
                                font-size: 0.24rem;
                                font-family: 'SFPixelate-Bold';
                                line-height: 0.28rem;
                                margin-top: 0.16rem;
                                transition: 0s;
                            }
                        }
                        a {
                            width: 0.32rem;
                            height: 0.32rem;
                            background: url('~@/assets/images/market-detail/share_ico.svg') no-repeat center;
                            background-size: contain;
                            margin-left: 0.16rem;
                            margin-top: 0.16rem;
                        }
                    }
                    .rent-box {
                        margin-top: 0.16rem;
                        color: #ffffff;
                        font-size: 0.24rem;
                        font-family: 'SFPixelate-Bold';
                    }
                }
            }
        }
    }
}
@keyframes ant-skeleton-loading {
    0% {
        background-position: 100% 50%;
    }
    to {
        background-position: 0 50%;
    }
}
</style>
